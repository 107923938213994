@import "index.css";

:root {
  --atomic-pink: #ff00cc;
  --shadow-gray: rgba(0, 0, 0, 0.2);
}

.btn {
  padding: 0.5rem;
  font-size: 1rem;
  margin-top: 1rem;
  border: 3px solid var(--atomic-pink);
  border-radius: 10px;
  color: var(--atomic-pink);
  background-color: white;
  transition: 0.5s ease;
  box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
}

.btn:hover {
  cursor: pointer;
  color: white;
  background-color: var(--atomic-pink);
  box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.5);
}

form {
  margin: 2rem;
  align-items: center;
  text-align: start;
  justify-content: center;
  overflow: scroll;
}

.input-field-container {
  padding: 0.1rem 0.3rem;
  border-radius: 20px;
  width: 70%;
  margin: 0.2rem;
  border: 3px solid black;
  text-align: flex-start;
  align-items: center;
  overflow: scroll;
  cursor: text;
  transition: 0.5s ease;
}

.input-field-container:hover {
  border: 3px solid var(--atomic-pink);
}

.input-field-container:focus-within {
  border: 3px solid var(--atomic-pink);
}

.input-field {
  margin: 0.6rem 0.3rem;
  width: 95%;
  border: none;
  overflow-x: scroll;
  font-size: 1rem;
}

.input-field:focus {
  outline: none;
}

.map-wrap {
  position: absolute;
  width: 100%;
  /* height: calc(
    100vh - 177px
  ); calculate height of the screen minus the heading */
  /* padding-bottom: 50vh; */
  justify-content: center;
  align-items: center;
  margin: auto;
}

.map {
  position: relative;
  margin: 0rem 1rem;
  width: 90vw;
  height: 80vh;
  border-radius: 20px;
  border: 1px solid gray;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.mapbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: auto;
}

.signInOut-btn {
  position: absolute;
  z-index: 1;
  width: 35px;
  height: 35px;
  top: 120px;
  left: 10px;
  /* background-color: #fff; */
  border: none;
  border-radius: 5px;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.12);
  background: url("configure.png") no-repeat white;
  padding: 1rem;
  background-position: center;
  background-size: 20px 20px;
  cursor: pointer;
}

.adminTools {
  position: relative;
  display: flex;
  padding: 1rem 2rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  overflow-y: scroll;
  margin: auto;
  /* justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center; */
}

.newBtn {
  padding: 0px 10px;
  width: 8rem;
  font-size: 1rem;
  text-align: center;
  border: 3px solid var(--atomic-pink);
  border-radius: 10px;
  background-color: var(--atomic-pink);
  color: white;
  transition: 0.5s ease-in-out;
  box-shadow: 0.2rem 0.2rem 0.1rem rgba(0, 0, 0, 0.2);
}

.newBtn:hover,
.newBtn-active:hover {
  cursor: pointer;
  box-shadow: 0.5rem 0.5rem 0.1rem rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.editBtn {
  padding: 0px 10px;
  /* font-size: 1rem; */
  width: 8rem;
  text-align: center;
  border: 3px solid gray;
  border-radius: 10px;
  background-color: white;
  color: gray;
  transition: 0.5s ease-in-out;
  box-shadow: 0.2rem 0.2rem 0.1rem rgba(0, 0, 0, 0.2);
}

.editBtn-active {
  padding: 0px 10px;
  width: 8rem;
  text-align: center;
  font-size: 1rem;
  border: 3px solid black;
  border-radius: 10px;
  background-color: black;
  color: white;
  transition: 0.5s;
  box-shadow: 0.2rem 0.2rem 0.1rem rgba(0, 0, 0, 0.2);
}

.editBtn:hover {
  cursor: pointer;
  border: 3px solid black;
  box-shadow: 0.5rem 0.5rem 0.1rem rgba(0, 0, 0, 0.2);
  color: black;
  transition: 0.5s;
}

.editBtn-active:hover {
  box-shadow: 0.5rem 0.5rem 0.1rem rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.coordinateLabels {
  width: auto;
  height: auto;
}

.markerBtn {
  justify-content: center;
  margin: 15px;
  width: 15vw;
  height: 8vh;
  font-size: 1.2rem;
  background-color: white;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s ease;
}

.markerBtn:hover {
  font-size: 1.3rem;
  /* outline: 2px solid gray; */
}

#radio_marker {
  background-image: url("radio-marker.png");
  color: white;
  background-size: cover;
  width: 40px;
  height: 55px;
  cursor: pointer;
  top: -25px; /* positions markers point onto its exact coordinate*/
}

#radio_marker-inactive {
  background-image: url("radio-marker-inactive.png");
  color: white;
  background-size: cover;
  width: 40px;
  height: 55px;
  cursor: pointer;
  top: -25px; /* positions markers point onto its exact coordinate*/
}

#event_marker {
  background-image: url("event-marker.png");
  background-size: cover;
  width: 40px;
  height: 55px;
  cursor: pointer;
  top: -25px; /* positions markers point onto its exact coordinate*/
}

#event_marker-inactive {
  background-image: url("event-marker-inactive.png");
  background-size: cover;
  width: 40px;
  height: 55px;
  cursor: pointer;
  top: -25px; /* positions markers point onto its exact coordinate*/
}

#record_marker {
  background-image: url("record-marker.png");
  color: white;
  background-size: cover;
  width: 40px;
  height: 55px;
  cursor: pointer;
  top: -25px; /* positions markers point onto its exact coordinate*/
}

#record_marker-inactive {
  background-image: url("record-marker-inactive.png");
  color: white;
  background-size: cover;
  width: 40px;
  height: 55px;
  cursor: pointer;
  top: -25px; /* positions markers point onto its exact coordinate*/
}

#editMarkerCancel-btn {
  border: 3px solid black;
  color: black;
}

#editMarkerCancel-btn:hover {
  background-color: black;
  color: white;
}

#editMarkerDelete-btn {
  border: 3px solid red;
  color: red;
}

#editMarkerDelete-btn:hover {
  background-color: red;
  color: white;
}

.modal-container {
  margin-top: -60px;
}

.marker-form {
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  width: 25rem;
  row-gap: 5px;
  z-index: 2;
}

.newMarker-form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 25rem;
  row-gap: 5px;
}

.marker-form-title {
  margin-bottom: 0px;
  /* margin-top: -10px; */
  color: var(--atomic-pink);
}

/* .maplibregl-ctrl-top-right, */
.maplibregl-ctrl-bottom-right {
  display: none;
}

.buttons-container {
  display: grid;
  width: 400px;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;
}

.btn-status {
  padding: 0.5rem;
  font-size: 1rem;
  margin-top: 1rem;
  border-radius: 10px;
  transition: 0.5s ease;
  box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.btn-status:hover {
  box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.5);
}

#status-active {
  border: 3px solid var(--atomic-pink);
  color: #fff;
  background-color: var(--atomic-pink);
}

#status-inactive {
  color: rgba(0, 0, 0, 0.5);
  border: 3px solid rgba(0, 0, 0, 0.5);
  background-color: #fff;
}
